import moment from 'moment';


const domains = {
    slotauto: `https://bo.slotautospin.com/api`,
    igamesea: `https://bo.igamesea.com/api`,
    lionbet: `https://ag.168lionbet.com/api`,
    phenix295: `https://office.phenix295.com/api`,
    phenix168: `https://office.phenix168.com/api`,
    ufo98: `https://office.ufo89bet.com/api`,
    vipaclub: `https://office.vipaclub88.com/api`,
    octa8888: `https://office.octa8888.com/api`,
    lion168: `https://office.168lionx.com/api`,
    sg168: `https://office.sgaming168.com/api`,
    axe888: `https://office.axe888.com/api`,
    wisdom: `https://office.wisdoms.asia/api`,
    diamond: `https://office.diamond458.com/api`,
    slotslao: `https://office.slotslao.com/api`,
    afcbet: `https://office.afcbet.net/api`,
    pklaos88: `https://office.pklaos88.com/api`,
    wisdom368: `https://office.wisdom368.com/api`,
    ox888: `https://office.ox888.vip/api`,
    bugano168: `https://office.bugano168.com/api`,
    ferrari888: `https://office.ferrari888.vip/api`,
    asia88x: `https://office.88x.asia/api`,
    theking888: `https://office.theking888.net/api`,
    victory68: `https://office.victory68.asia/api`,
    local: `http://127.0.0.1:5800`,
    web789x: `https://office.789x.asia/api`,
}
const logos = {
    slotauto: `logo.png`,
    igamesea: `logo.png`,
    lionbet: `logo-168.png`,
    phenix295: `logo.png`,
    phenix168: `logo-phenix168.png`,
    ufo98: `logo-ufo.png`,
    vipaclub: `logo-vipaclub.png`,
    octa8888: `logo-octa8888.png`,
    lion168: `logo-lion168.png`,
    sg168: `logo-sg168.png`,
    axe888: `logo-axe888.png`,
    wisdom: `logo-wisdom.png`,
    diamond: `logo-diamond.png`,
    slotslao: `logo-slotslao.png`,
    afcbet: `logo-afcbet.png`,
    pklaos88: `logo-pklaos88.png`,
    wisdom368: `logo-wisdom368.png`,
    ox888: `logo-ox888.png`,
    bugano168: `logo-bugano168.png`,
    ferrari888: `logo-ferrari888.png`,
    asia88x: `logo-88x.png`,
    theking888: `logo-theking888.png`,
    victory68: `logo-victory68.png`,
    web789x: `logo-789x.png`,
}


export const logo = logos.web789x
//export const domain = "https://apiv1.octa8888.com"
export const domain = domains.web789x

// export const domain = domains.diamond
// export const domain = `https://bo.igamesea.com/api`;
// export const domain = `https://bo.igamesea.com/api`;
// export const domain = `https://ag.168lionbet.com/api`;
// export const domain = `https://office.phenix295.com/api`;
export const AUTHKEY = "AUTH";

export const initConfigInputTime = {
    "showDropdowns": true,
    "timePicker": true,
    "timePicker24Hour": true,
    "autoApply": true,
    showCustomRangeLabel: false,
    parentEl: ".modal-body",
    // "autoUpdateInput": true,
    ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days').format("YYYY-MM-DD HH:mm"), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    "alwaysShowCalendars": true,
    "startDate": moment(),
    "endDate": moment(),
}

export const initConfigInputLastMonth = {
    "showDropdowns": true,
    "timePicker": true,
    "timePicker24Hour": true,
    "autoApply": true,
    showCustomRangeLabel: false,
    parentEl: ".modal-body",
    // "autoUpdateInput": true,
    ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    "alwaysShowCalendars": true,
    "startDate": moment().subtract('months', 1),
    "endDate": moment(),
}
